/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */

import { Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ebookBookTemplateActions } from '@orientaction/api-actions';
import {
  BarEdition,
  Editor,
  ItemChapter,
  ItemPage,
  Layout,
  SimpleModal,
} from '@orientaction/components';
import {
  EditorCitation,
  ModalEditingEbook,
  TextAreaQuestion,
} from '@orientaction/components/Admin';
import { ebookBookValues } from '@orientaction/reducers';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Add } from '../../../Asset/svg/add_white.svg';
import { ReactComponent as Broder } from '../../../Asset/svg/border_white.svg';
import { ReactComponent as Browser } from '../../../Asset/svg/browser.svg';
import { ReactComponent as Close } from '../../../Asset/svg/close.svg';
import { ReactComponent as Delete } from '../../../Asset/svg/delete.svg';
import { ReactComponent as Enc } from '../../../Asset/svg/enc.svg';
import { ReactComponent as Move } from '../../../Asset/svg/move.svg';
import { ReactComponent as RemoveB } from '../../../Asset/svg/removeB.svg';
import { ReactComponent as Save } from '../../../Asset/svg/save.svg';
import { apiServiceNative } from '../../../utils/fetchNative';
import Loader from '../../AdminGame/EditPage/loader-edit';
import ModalEditGame from '../../AdminGame/EditPage/modal-edit-game';
import style from '../style';
import ItemBlocks from './ItemBlock';
import './index.css';

const EditEbookPage = (props: any) => {
  const classes = style();
  const [showEdition, setShowEdition] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [templateBlocks, setTemplateBlocks] = useState<any>([]);
  const [allBlocks, setAllBlock] = useState<any>([]);
  const [indexSelected, setIndexSelected] = useState<any>(0);
  const [blockSelected, setBlockSelected] = useState<any>({});
  const [valueInput, setValueInput] = useState<any>();
  const [defaultValue, setAllDefaultValue] = useState<any>(allBlocks);
  const [pulseMove, setPulseMove] = useState<number>(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { id }: any = useParams();
  const valuesEbooksTemplate = useSelector(ebookBookValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openExit, setOpenExit] = useState(false);
  const [open, setOpen] = useState(false);
  const [textLoading, setTextLoading] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const imgRef = useRef<any>(null);
  const [url, setUrl] = useState('');
  const [openEditImg, setOpenEditImg] = useState<boolean>(false);
  const [imageDimensions, setImageDimensions] = useState<any>({});
  const [indexEditImg, setIndexEditImg] = useState<any>(null);
  const containerRef = useRef<any>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      const container: any = containerRef.current;
      if (container) {
        container.scrollTo({ top: container.scrollHeight, left: 0, behavior: 'smooth' });
      }
    }, 500);
  };

  useEffect(() => {
    sessionStorage.setItem('book', 'true');
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/bloc-templates?populate=*&sort=id:asc`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const dataTemplateApi = await response.json();

        setTemplateBlocks(dataTemplateApi?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedId = sessionStorage.getItem('id') || id;
        if (storedId && !sessionStorage.getItem('fromEdit')) {
          const response = await apiServiceNative.get(`/api/ebook-details/${storedId}`);
          dispatch(ebookBookTemplateActions.setAllValues(response));
          setAllBlock(response?.content);
          sessionStorage.setItem('id', storedId);
        } else {
          setAllBlock(valuesEbooksTemplate?.content || []);
        }
        sessionStorage.removeItem('fromEdit');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch, id]);

  const handleChangeNumLines = (delta: number, index: number) => {
    const newBlocks = [...allBlocks];

    newBlocks[index].numLines = delta || 1;
    setAllBlock(newBlocks);
  };

  const renderLines = (numLines: number) => {
    return (
      <div className="lines-container">
        {[...Array(numLines)].map((_, index) => (
          <div key={index} className={`line ${index === numLines - 1 ? 'last-line' : ''}`} />
        ))}
      </div>
    );
  };
  const [styleEditor, setStyleEditor] = useState<any>();
  const [stateEdition, setStateEdition] = useState<any>();
  const [showLinkPopup, setShowLinkPopup] = useState<boolean>(false);
  const [position, setPosition] = useState<string>();

  const editStyleEditor = (styleCommand: any, arg: any) => {
    console.log({ styleCommand });
    if (allBlocks[indexSelected]?.bloc_template === 1) {
      setAllBlock((prevData: any) => {
        const dataPrev = [...prevData];
        dataPrev[indexSelected].styleEditor = { styleCommand, arg };
        dispatch(ebookBookTemplateActions.updateField('content', dataPrev));
        return dataPrev;
      });
    }
  };
  const editPosition = (alignement: any) => {
    console.log({ alignement });
    if (allBlocks[indexSelected]?.bloc_template === 1) {
      setAllBlock((prevData: any) => {
        const dataPrev = [...prevData];
        dataPrev[indexSelected].alignement = alignement;
        dispatch(ebookBookTemplateActions.updateField('content', dataPrev));
        return dataPrev;
      });
    }
  };

  const renderTypeParagraphe = (block: any, index: number) => {
    switch (block?.contentForType) {
      case 'normal':
        return (
          <div>
            <Editor
              isSmall={true}
              handleActiveStyles={(data: any) => {
                console.log({ data });
                setStateEdition(data);
              }}
              handleFocused={(isFocused: boolean) => {
                console.log(isFocused);
                setShowEdition(isFocused);
              }}
              styleEditor={block?.styleEditor}
              showLinkPopupE={!!block?.showLinkPopup}
              defaultValue={block?.paragraphe}
              key={index}
              isEbook={true}
              handleChange={(content) => handleChangeInput(content, index, 'paragraphe')}
              pulseMove={pulseMove}
              position={block?.alignement}
              handlePosition={(pos) => {
                setPosition(pos);
                handleChangeInput(pos, index, 'alignement');
              }}
            />
          </div>
        );
      case 'citation':
        return (
          <EditorCitation
            key={index}
            defaultValue={block?.citation}
            defaultAutor={block?.author}
            handleChange={({ citation, autor }) => {
              handleChangeInput(citation, index, 'citation');
              handleChangeInput(autor, index, 'author');
            }}
            isEbook={true}
          />
        );
      case 'encyclopedia':
        return (
          <div className={classes.enclopedieContainer}>
            <Enc className={classes.iconEnc} />
            <div style={{ width: '100%' }}>
              <Editor
                isSmall={true}
                handleActiveStyles={(data: any) => {
                  console.log({ data });
                  setStateEdition(data);
                }}
                handleFocused={(isFocused: boolean) => {
                  console.log(isFocused);
                  setShowEdition(isFocused);
                }}
                styleEditor={block?.styleEditor}
                showLinkPopupE={!!block?.showLinkPopup}
                defaultValue={block?.encyclopedia}
                key={index}
                isEbook={true}
                handleChange={(content) => handleChangeInput(content, index, 'encyclopedia')}
                pulseMove={pulseMove}
                placeholder="Cliquer pour ajouter une information"
                position={block?.alignement}
                handlePosition={(pos) => {
                  setPosition(pos);
                  handleChangeInput(pos, index, 'alignement');
                }}
              />
            </div>
          </div>
        );
      default:
    }
  };

  const handleAddUpdate = async (isPublish?: boolean) => {
    console.log({ allBlocks });
    const data = {
      ...valuesEbooksTemplate,
      content: allBlocks,
      publishedAt: isPublish ? new Date() : null,
    };

    localStorage.removeItem('idFILESTemps');
    !isPublish
      ? setTextLoading('Sauvegarde en cours...')
      : setTextLoading('Publication en cours...');

    setLoading(true);
    const ebookId = sessionStorage.getItem('id');

    if (ebookId) {
      try {
        const response = await apiServiceNative.put(`/api/update-serious-ebook/${ebookId}`, {
          data,
        });
        setLoading(false);
        dispatch(ebookBookTemplateActions.setAllValues(response));
        setAllBlock(response?.content);
      } catch (error) {
        setLoading(false);
        console.error('Update error:', error);
      }
    } else {
      try {
        const response = await apiServiceNative.post('/api/create-ebook', { data });
        dispatch(ebookBookTemplateActions.setAllValues(response));
        setAllBlock(response?.content);
        sessionStorage.setItem('id', response?.id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Create error:', error);
      }
    }
  };

  const renderTypeBlock = (block: any, index: number) => {
    switch (block?.bloc_template) {
      case 1:
        return renderTypeParagraphe(block, index);
      case 2:
        return (
          <input
            id={`title-${index}`}
            className={classes.editArea}
            style={{
              height: 30,
              fontWeight: block?.title_type === 'subtitle' ? 600 : 700,
              fontSize: block?.title_type === 'subtitle' ? 14 : 16,
            }}
            type="text"
            placeholder="Cliquer pour ajouter un titre"
            value={block.title}
            onInput={(e: any) => handleChangeInput(e.target.value, index, 'title')}
          />
        );

      case 3:
        return (
          <>
            <div className="qcm-block">
              <TextAreaQuestion
                placeholder="Cliquer pour rédiger une question"
                minHeight={21}
                defaultValue={allBlocks[index]?.free_question_title}
                handleChange={(value: any) => {
                  console.log({ value });
                  handleChangeInput(value, index, 'qcm_title');
                }}
              />
              {allBlocks[index]?.qcm?.[0]?.response?.map((response: any, responseIndex: number) => (
                <div key={response.id} className="option" style={{ paddingTop: 5 }}>
                  {response.isOther ? (
                    <div style={{ width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input type="radio" style={{ margin: '0px 5px 0px 0px' }} disabled={true} />
                        <span className="option-title" style={{ paddingLeft: 5 }}>
                          Autre
                        </span>
                      </div>
                      <textarea
                        style={{ outline: 'none', border: 'none', borderRadius: 0, fontSize: 13 }}
                        className={classes.editArea}
                        placeholder="Champ texte réservé aux bénéficiaires"
                        value={response.responseText}
                        readOnly={true}
                      />
                    </div>
                  ) : (
                    <>
                      <input type="radio" style={{ margin: '0px 5px 0px 0px' }} disabled={true} />
                      <TextAreaQuestion
                        placeholder="Entrer une réponse"
                        minHeight={21}
                        defaultValue={response.responseText}
                        handleChange={(value: any) => {
                          handleChangeOption(index, responseIndex, value);
                        }}
                        isRadio={true}
                      />
                      {/* <input
                        type="text"
                        placeholder="Entrer une réponse"
                        value={response.responseText}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeOption(index, responseIndex, e.target.value)
                        }
                        className="normal-input"
                      /> */}
                    </>
                  )}
                  <IconButton
                    aria-label="delete"
                    className="delete-button"
                    style={{ background: 'transparent' }}
                    onClick={() => handleDeleteOption(index, responseIndex)}
                  >
                    <RemoveB style={{ width: 15, height: 15 }} />
                  </IconButton>
                </div>
              ))}
              <div className="btnBlockBtn">
                <button className="button add-option" onClick={() => handleAddOption(index)}>
                  Ajouter une option
                </button>
                <button
                  className="button add-option btn-autre"
                  onClick={() => handleAddOption(index, true)}
                >
                  Ajouter "Autre"
                </button>
              </div>
            </div>
          </>
        );

      case 4:
        return (
          <>
            <div className="text-area-block">
              <TextAreaQuestion
                placeholder="Cliquer pour rédiger une question"
                minHeight={21}
                defaultValue={allBlocks[index]?.free_question_title}
                handleChange={(value: any) => {
                  console.log({ value });
                  handleChangeInput(value, index, 'free_question_title');
                }}
              />
              {/* <input
                className="editArea"
                type="text"
                placeholder="Cliquer pour rédiger une question"
                value={allBlocks[index]?.free_question_title}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(e.target.value, index, 'free_question_title')
                }
              /> */}
              <label className="line-control">
                Nombre de lignes disponibles pour la réponse:
                <input
                  type="number"
                  value={allBlocks[index].numLines}
                  min={1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeNumLines(parseInt(e.target.value, 10), index)
                  }
                  className="num-lines-input"
                />
              </label>
              {renderLines(allBlocks[index].numLines)}
            </div>
          </>
        );
      case 5:
        return (
          <div style={{ position: 'relative', padding: 5 }}>
            <img src={block?.img?.url} alt="" style={{ width: '100%', display: 'block' }} />
            <div className={classes.iconBtnImage} key={url}>
              <IconButton
                aria-label="delete"
                style={{ background: '#E63C3C' }}
                onClick={() => {
                  const updatedItems = [...allBlocks];
                  updatedItems.splice(index, 1);
                  console.log(updatedItems);
                  setAllBlock(updatedItems);
                }}
              >
                <Close className={classes.icon} />
              </IconButton>
              <IconButton
                aria-label="delete"
                style={{ background: '#000A8C', marginTop: 15 }}
                onClick={() => {
                  const img = new Image();
                  img.src = block?.img?.url;
                  img.onload = () => {
                    setImageDimensions({
                      height: img.height,
                      width: img.width,
                    });
                  };
                  img.onerror = (err) => {
                    console.log('img error');
                    console.error(err);
                  };
                  setUrl(block?.img?.url);
                  setOpenEditImg(true);
                  setIndexEditImg(index);
                }}
              >
                <Broder className={classes.icon} />
              </IconButton>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={classes.containerSommaire}>
            <div className={classes.titleSommaire} style={{ fontWeight: 700, fontSize: 18 }}>
              Sommaire
            </div>
            {allBlocks?.map((chapter: any, i: number) => {
              if (chapter?.bloc_template === 2) {
                return (
                  <div
                    className={
                      chapter.title_type === 'subtitle'
                        ? classes.subtitleSommaire
                        : classes.titleSommaire
                    }
                  >
                    {chapter.title}
                  </div>
                );
              }
            })}
          </div>
        );
      default:
    }
  };
  const handleChangeInput = (value: any, index: number, field: string) => {
    setAllBlock((prevBlocks: any) => {
      const newItems = [...prevBlocks];
      newItems[index][field] = value;
      dispatch(ebookBookTemplateActions.updateField('content', newItems));

      return newItems;
    });
  };

  const handleChangeOption = (blockIndex: number, responseIndex: number, value: string) => {
    const newBlocks = [...allBlocks];
    if (newBlocks[blockIndex].qcm && newBlocks[blockIndex].qcm[0]?.response) {
      newBlocks[blockIndex].qcm[0].response[responseIndex].responseText = value;
    }
    setAllBlock(newBlocks);
    dispatch(ebookBookTemplateActions.updateField('content', newBlocks));
  };

  const handleAddOption = (index: number, isOther = false) => {
    const newBlocks = [...allBlocks];
    const newQCM = newBlocks[index].qcm ? [...newBlocks[index].qcm] : [];
    const newResponse = newQCM[0]?.response ? [...newQCM[0].response] : [];
    newResponse.push({ idx: newResponse.length + 1, responseText: '', score: null, isOther });
    if (newQCM.length > 0) {
      newQCM[0].response = newResponse;
    } else {
      newQCM.push({ idx: 1, question: '', type: 'radio', response: newResponse });
    }
    newBlocks[index].qcm = newQCM;
    setAllBlock(newBlocks);
    dispatch(ebookBookTemplateActions.updateField('content', newBlocks));
  };

  const handleDeleteOption = (blockIndex: number, optionIndex: number) => {
    const newBlocks = [...allBlocks];
    if (newBlocks[blockIndex].qcm && newBlocks[blockIndex].qcm[0]?.response) {
      newBlocks[blockIndex].qcm[0].response = newBlocks[blockIndex].qcm[0].response.filter(
        (response: any, i: any) => i !== optionIndex,
      );
    }
    setAllBlock(newBlocks);
    dispatch(ebookBookTemplateActions.updateField('content', newBlocks));
  };

  // const selectType = (idBlock: any) => {
  //   const newType = {
  //     idBlock,
  //     value: '',
  //     typeTitle: 'title',
  //   };
  //   setAllBlock([...allBlocks, newType]);
  // };
  // console.log('allBlocks', allBlocks);

  const selectType = (bloc_template: any) => {
    if (bloc_template !== 5) {
      const indexLast = parseInt(allBlocks?.length, 10);
      const newType = {
        bloc_template,
        title: '',
        paragraphe: '',
        encyclopedia: null,
        free_question_title: '',
        author: '',
        title_type: bloc_template === 2 ? 'title' : '',
        img: null,
        qcm: [],
        value: '',
        numLines: 7,
        styleEditor: null,
        contentForType: 'normal',
        citation: '',
        alignement: 'flex-start',
      };
      setAllBlock([...allBlocks, newType]);
      dispatch(ebookBookTemplateActions.updateField('content', [...allBlocks, newType]));
      setShowMenu(false);
      console.log({ indexLast });
      setIndexSelected(indexLast);
      scrollToBottom();
    } else {
      const imgR = imgRef.current;
      if (imgR) {
        imgR.click();
      }
    }
  };

  const moveElement = (fromIndex: number, toIndex: number) => {
    const updatedItems = [...allBlocks];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setAllBlock(updatedItems);
    dispatch(ebookBookTemplateActions.updateField('content', updatedItems));
    setIndexSelected(toIndex);
    setBlockSelected(updatedItems[toIndex]);
    setPulseMove((prev: number) => prev + 1);
  };

  const handleInputChange = (e: any) => {
    if (allBlocks?.length > 0) {
      const value = e.target.value.trim();
      const maxPages = parseInt(allBlocks?.length || 0, 10);

      const numericValue = parseInt(value, 10);
      setValueInput(
        value === ''
          ? ''
          : isNaN(numericValue)
          ? ''
          : numericValue >= maxPages
          ? maxPages
          : numericValue < 1
          ? 1
          : numericValue,
      );
      if (!isNaN(numericValue)) {
        moveElement(
          indexSelected,
          (numericValue >= maxPages ? maxPages : numericValue < 1 ? 1 : numericValue) - 1,
        );
      }
    }
  };

  useEffect(() => {
    if (!isNaN(indexSelected)) {
      setValueInput(indexSelected + 1);
    }
  }, [indexSelected]);

  const handleConfirm = () => {
    if (openExit) {
      setOpenExit(false);
      setOpen(false);
      ebookBookTemplateActions.resetValuesEbooks();
      sessionStorage.setItem('fromEbook', 'true');
      history.push(`/admin/game/list`);
    } else {
      handleAddUpdate(true);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    // handle cancel action
    setOpen(false);
    setOpenExit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenExit(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const message = 'Êtes-vous sûr de vouloir quitter sans sauvegarder vos modifications ?';
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleChangeImg = (acceptedFiles: any) => {
    const uri = URL.createObjectURL(acceptedFiles[0]);
    setUrl(uri);
    setOpenEditImg(true);
    if (uri) {
      const img = new Image();
      img.src = uri;

      img.onload = () => {
        setImageDimensions({
          height: img.height,
          width: img.width,
        });
      };
      img.onerror = (err) => {
        console.log('img error');
        console.error(err);
      };
      setShowMenu(false);
    }
  };

  const handleChangeInputImg = (uri: any, i: any) => {
    if (indexEditImg === null) {
      if (uri) {
        const indexLast = parseInt(allBlocks.length, 10);
        const newType = {
          bloc_template: 5,
          title: '',
          paragraphe: '',
          encyclopedia: null,
          free_question_title: '',
          author: '',
          title_type: '',
          img: {
            id: i,
            url: uri,
          },
          qcm: [],
          value: '',
          numLines: 7,
          styleEditor: null,
          contentForType: 'normal',
          citation: '',
          alignement: 'flex-start',
        };
        setAllBlock([...allBlocks, newType]);
        console.log({ indexLast });
        setIndexSelected(indexLast);
        scrollToBottom();
      }
    } else {
      setAllBlock((prevBlocks: any) => {
        const newItems = [...prevBlocks];
        newItems[indexEditImg].img.url = uri;
        console.log({ newItems });
        dispatch(ebookBookTemplateActions.updateField('content', newItems));
        return newItems;
      });
      setIndexEditImg(null);
    }
  };

  const deleteBlock = () => {
    const updatedItems = [...allBlocks];
    updatedItems.splice(indexSelected, 1);
    setAllBlock(updatedItems);
    setPulseMove((prev: number) => prev + 1);
    setOpenModal(false);
  };

  const addLink = (showLink: any) => {
    if (allBlocks[indexSelected]?.bloc_template === 1) {
      setAllBlock((prevData: any) => {
        const dataPrev = [...prevData];
        dataPrev[indexSelected].showLinkPopup = showLink;
        return dataPrev;
      });
    }
  };

  const scrollToSection = (index: any) => {
    const element = document.getElementById(`title-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Layout isaddBackground={true} showOverFlow={true}>
      <>
        <Loader open={loading} text={textLoading} />
        <ModalEditGame
          open={open || openExit}
          handleClose={handleClose}
          title={valuesEbooksTemplate?.title}
          message={
            open
              ? `Vous êtes sur le point de publier l'ebook`
              : `Êtes-vous sûr de vouloir quitter l'édition du book ?`
          }
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
        <Grid container={true}>
          <Grid item={true} sm={2} className={classes.section1}>
            <div className={classes.inner}>
              <div
                className={`${classes.wrapbutton}`}
                // onMouseEnter={() => handleMouseEnter(0)}
                // onMouseLeave={() => handleMouseLeave(0)}
              >
                <IconButton
                  aria-label="delete"
                  className={classes.iconButton}
                  onClick={() => {
                    setOpenExit(true);
                  }}
                >
                  <Move className={classes.icon} />
                </IconButton>
                <div className={classes.tooltipLabel}>Quitter sans sauvegarder</div>
              </div>
              <div
                className={`${classes.wrapbutton}`}
                // onMouseEnter={() => handleMouseEnter(1)}
                // onMouseLeave={() => handleMouseLeave(1)}
              >
                <IconButton
                  aria-label="save"
                  className={classes.iconButton}
                  onClick={() => {
                    handleAddUpdate();
                  }}
                >
                  <Save className={classes.icon} />
                </IconButton>
                <div className={classes.tooltipLabel}>Sauvegarder comme brouillon</div>
              </div>
              <div
                className={`${classes.wrapbutton}`}
                //  onMouseEnter={() => handleMouseEnter(2)}
                // onMouseLeave={() => handleMouseLeave(2)}
              >
                <IconButton
                  aria-label="delete"
                  className={classes.iconButton}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Browser className={classes.icon} />
                </IconButton>
                <div className={classes.tooltipLabel}>Publier</div>
              </div>
            </div>
          </Grid>
          <Grid item={true} sm={10} className={classes.section2}>
            <div style={{ padding: '10px 30px', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container={true}>
                  <Grid item={true} sm="auto" className={classes.containerGrid}>
                    <div className={classes.containerPageNumber}>
                      <Typography variant="h4" className={classes.title2}>
                        Position du bloc
                      </Typography>
                      <TextField
                        variant="outlined"
                        className={classes.formInputNumber}
                        name="test-name"
                        type="text"
                        inputProps={{
                          min: 1,
                          max: parseInt(((allBlocks?.length - 1) as any) || 0, 10),
                        }}
                        value={valueInput}
                        onChange={handleInputChange}
                      />
                      <div className={classes.containerIconS}>
                        <KeyboardArrowUp
                          onClick={() => {
                            if (indexSelected > 0 && allBlocks?.length > 0) {
                              moveElement(indexSelected, indexSelected - 1);
                            }
                          }}
                          className={classes.iconS}
                        />
                        <KeyboardArrowDown
                          onClick={() => {
                            if (
                              indexSelected < parseInt(((allBlocks?.length - 1) as any) || 0, 10) &&
                              allBlocks?.length > 0
                            ) {
                              moveElement(indexSelected, indexSelected + 1);
                            }
                          }}
                          className={classes.iconS}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item={true} sm="auto" className={classes.containerGrid}>
                    <Button
                      color="primary"
                      style={{ marginLeft: 0 }}
                      startIcon={<Delete className={classes.iconbig} />}
                      onClick={() => setOpenModal(true)}
                    >
                      <Typography variant="h4" className={classes.title2}>
                        Supprimer le bloc
                      </Typography>
                    </Button>
                    <SimpleModal
                      open={openModal}
                      handleClose={() => setOpenModal(false)}
                      title="Information"
                      message="Êtes vous sur de vouloir supprimer le bloc ?"
                      textClick="Oui"
                      textClose="Non"
                      handleClick={deleteBlock}
                    />
                  </Grid>
                  {allBlocks &&
                    allBlocks?.length > 0 &&
                    allBlocks[indexSelected]?.bloc_template === 2 && (
                      <Grid
                        item={true}
                        sm="auto"
                        className={classes.containerGrid}
                        style={{ borderLeft: '1px solid #7C7C7C' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginLeft: 10,
                            marginRight: 10,
                            padding: '5px 7.5px',
                            background: '#ECECEC',
                          }}
                        >
                          <span style={{ fontSize: 11 }}>Type de titre</span>
                          <select
                            className={classes.selectLine}
                            style={{ margin: 0 }}
                            value={blockSelected?.title_type}
                            onChange={(e) =>
                              handleChangeInput(e.target.value, indexSelected, 'title_type')
                            }
                          >
                            <option value="title">Titre</option>
                            <option value="subtitle">Sous titre</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  {allBlocks &&
                    allBlocks.length > 0 &&
                    allBlocks[indexSelected]?.bloc_template === 1 && (
                      <Grid
                        item={true}
                        sm="auto"
                        className={classes.containerGrid}
                        style={{ borderLeft: '1px solid #7C7C7C' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginLeft: 10,
                            marginRight: 10,
                            padding: '5px 7.5px',
                            background: '#ECECEC',
                          }}
                        >
                          <span style={{ fontSize: 11 }}>Type de texte</span>
                          <select
                            className={classes.selectLine}
                            style={{ margin: 0 }}
                            value={blockSelected?.contentForType}
                            onChange={(e) =>
                              handleChangeInput(e.target.value, indexSelected, 'contentForType')
                            }
                          >
                            <option value="normal">Normal</option>
                            <option value="citation">Citation</option>
                            <option value="encyclopedia">Encyclopedie</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  {allBlocks &&
                    allBlocks?.length > 0 &&
                    allBlocks[indexSelected]?.bloc_template === 1 &&
                    allBlocks[indexSelected]?.contentForType !== 'citation' && (
                      <Grid item={true} sm={12} style={{ marginLeft: '-12px', marginTop: 3 }}>
                        <BarEdition
                          stateEdition={stateEdition}
                          editState={editStyleEditor}
                          handleLink={addLink}
                          isEbook={false}
                          editPosition={editPosition}
                          position={position}
                        />
                      </Grid>
                    )}
                </Grid>
                <div style={{ position: 'relative' }}>
                  <Button
                    size="small"
                    color="primary"
                    disableRipple={true}
                    className={classes.btnAddBlock}
                    style={{ background: '#000A8C' }}
                    onClick={() => setShowMenu(!showMenu)}
                    endIcon={<Add className={classes.btnicon} />}
                  >
                    Ajouter un bloc
                  </Button>
                  {showMenu && (
                    <div className={classes.menuContainer}>
                      <div style={{ width: '100%', position: 'relative', height: 15 }}>
                        <div className={classes.polygoneTriangle} />
                      </div>
                      <div className={classes.menu}>
                        <h3 className={classes.titleBlock}>
                          Sélectionnez le type de bloc que vous souhaitez ajouter{' '}
                        </h3>
                        <Grid container={true} spacing={2}>
                          <input
                            type="file"
                            accept="image/*"
                            ref={imgRef}
                            style={{ display: 'none' }}
                            onChange={(e) => handleChangeImg(e.target.files)}
                          />
                          {templateBlocks?.map((block: any) => (
                            <Grid item={true} sm={6}>
                              <div
                                className={classes.containerBlock}
                                onClick={() => selectType(block.id)}
                              >
                                <img
                                  src={block?.attributes?.img?.data?.attributes?.url}
                                  alt={block?.attributes?.name}
                                  className={classes.imgBlock}
                                />
                                <h3 className={classes.titleItem}>{block?.attributes?.name}</h3>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </div>
                  )}
                  <ModalEditingEbook
                    open={openEditImg}
                    fileUrl={url}
                    handleClose={() => setOpenEditImg(false)}
                    handleUrl={(u: any, i: any) => handleChangeInputImg(u, i)}
                    imgHeightB={imageDimensions.height as any}
                    imgWidthB={imageDimensions.width}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container={true} style={{ background: '#F1F1F1' }}>
          {/* Debut Sommaire contextuel */}
          <Grid
            item={true}
            sm={2}
            className={classes.section3}
            onClick={() => setShowEdition(false)}
          >
            <ItemPage
              id="1"
              title={valuesEbooksTemplate?.title || 'titre du ebook'}
              url={
                valuesEbooksTemplate?.img?.url ||
                'https://storage.googleapis.com/orient-action.appspot.com/xx_85ae6992d2.png?updated_at=2024-05-27T18:25:35.428Z'
              }
              isCover={true}
              handleClick={() => history.push('/admin/ebook/edit-main/')}
            />
            <div style={{ height: '65vh', overflowY: 'auto' }}>
              {allBlocks &&
                allBlocks?.length > 0 &&
                allBlocks?.map((chapter: any, index: number) => {
                  if (chapter?.bloc_template === 2) {
                    return (
                      <ItemChapter
                        key={index}
                        id={index as any}
                        title={chapter.title}
                        isSubChapter={chapter.title_type === 'subtitle'}
                        isSelected={index === indexSelected}
                        handleClick={() => {
                          setIndexSelected(index);
                          setBlockSelected(chapter);
                          scrollToSection(index);
                        }}
                      />
                    );
                  }
                })}
            </div>
          </Grid>{' '}
          {/* Fin Sommaire contextuel */}
          <Grid ref={containerRef} item={true} sm={10} className={classes.section4}>
            <div className={classes.ebookContainer}>
              <img
                className={classes.imgCover}
                src={
                  valuesEbooksTemplate?.img?.url ||
                  'https://storage.googleapis.com/orient-action.appspot.com/xx_85ae6992d2.png?updated_at=2024-05-27T18:25:35.428Z'
                }
                alt=""
              />
              {allBlocks &&
                allBlocks?.length > 0 &&
                allBlocks?.map((block: any, index: number) => (
                  <ItemBlocks
                    key={index}
                    number={index + 1}
                    isSelected={index === indexSelected}
                    handleClick={() => {
                      setIndexSelected(index);
                      setBlockSelected(block);
                    }}
                  >
                    {renderTypeBlock(block, index)}
                  </ItemBlocks>
                ))}
              {/* <div style={{ width: '100%', height: 20 }}  /> */}
            </div>
          </Grid>
        </Grid>
      </>
    </Layout>
  );
};

export default EditEbookPage;
