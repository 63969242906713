/* eslint-disable array-callback-return */
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Layout } from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import style from '../style';
import { LayoutResultat } from '@orientaction/layouts';
import ItemEncyclopedie from '@orientaction/components/Admin/Game/ItemEncyclopedie';
import ItemCitation from '@orientaction/components/Admin/Game/ItemCitation';
import TextArea from '@orientaction/commons/TextArea';
import clsx from 'clsx';

const ViewEbook = (props: any) => {
  const classes = style();
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const token = getFromLS('userToken') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { id, index }: any = useParams();
  const [ebook, setEbook] = useState<any>([]);
  const [allBloks, setAllBlocks] = useState<any>([]);

  useEffect(() => {
    const fetchGameDetails = async () => {
      setLoading(true);
      try {
        const response = await apiServiceNative.get(`/api/ebook-front-user/${id}`);
        if (response) {
          setAllBlocks(response.ebook.content);
          setUrl(response.ebook.couverture);
          console.log({ response });
          // setUrl()
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();
  }, [id]);

  const renderTypeBlock = (block: any, ind: number) => {
    switch (block?.bloc_template) {
      case 1:
        return (
          <div
            style={
              block?.contentForType !== 'citation'
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 156,
                    justifyContent: block?.alignement || 'flex-start',
                  }
                : {}
            }>
            {block?.contentForType === 'normal' && (
              <div
                className={classes.blockContentTextNormal}
                style={{ color: '#000A8C' }}
                dangerouslySetInnerHTML={{ __html: block?.paragraphe }}
              />
            )}
            {block?.contentForType === 'citation' && (
              <ItemCitation text={block?.citation} autor={block?.author} isEbook={true} />
            )}
            {block?.contentForType === 'encyclopedia' && (
              <ItemEncyclopedie text={block?.encyclopedia} isEbook={true} />
            )}
          </div>
        );
      case 2:
        return (
          <div
            id={`title-${ind}`}
            className={block?.title_type === 'subtitle' ? classes.subtitleUser : classes.titleUser}>
            {block?.title}
          </div>
        );

      case 3:
        return (
          <div>
            <div className={classes.qcmTitle}>{block?.qcm_title}</div>
            <div style={{ marginTop: 15 }}>
              {block?.qcm[0]?.response?.map((q: any) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={q.id === parseInt(block?.response_qcm_user, 10)}
                        color="primary"
                        style={{ padding: 6 }}
                        onChange={() => updateQCM(q.apiType, ind, q.id)}
                        checkedIcon={<span className={clsx(classes.iconC, classes.checkedIcon)} />}
                        icon={<span className={classes.iconC} />}
                      />
                    }
                    label={
                      <div className={classes.labelQCM}>
                        {' '}
                        {q.typeReponse === 'text' ? 'Autre' : q.labelRadio}{' '}
                      </div>
                    }
                    style={{ paddingTop: 2, paddingBottom: 2, display: 'flex', marginLeft: -5 }}
                  />
                  {q.typeReponse === 'text' && (
                    <TextArea
                      name="text"
                      value={
                        q.id === parseInt(block?.response_qcm_user, 10)
                          ? block.response_qcm_text_user
                          : ''
                      }
                      notBorder={true}
                      onChange={e => updateQCMText(q.apiType, ind, q.id, e.target.value)}
                      readOnly={q.id !== parseInt(block?.response_qcm_user, 10)}
                      placeholder="Si autre, veuillez indiquer votre réponse"
                      editArea={true}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div>
            <div className={classes.qcmTitle}>
              <pre style={{ margin: 0, fontFamily: 'Poppins' }}>{block?.free_question_title}</pre>
            </div>
            <div style={{ marginTop: 15 }}>
              <TextArea
                name="text"
                value={block?.response_free_question_user || ''}
                notBorder={true}
                onChange={e => updateFreeQuestion(block?.updateUrl, e.target.value)}
                placeholder="Veuillez indiquer votre réponse"
                editArea={true}
                numberLine={block?.numLines}
              />
            </div>
          </div>
        );
      case 5:
        return <img src={block?.img} alt="" style={{ width: '100%', height: 'auto' }} />;
      case 6:
        return (
          <div className={classes.containerSommaireUser}>
            <div
              className={classes.titleSommaireUser}
              style={{ fontWeight: 700, fontSize: 18, color: '#E63C3C', marginTop: 0 }}>
              Sommaire
            </div>
            <div className={classes.containerChildrenSommaire}>
              {allBloks?.map((chapter: any, i: number) => {
                if (chapter?.bloc_template === 2) {
                  return (
                    <div
                      className={
                        chapter.title_type === 'subtitle'
                          ? classes.subtitleSommaireUser
                          : classes.titleSommaireUser
                      }
                      style={{ cursor: 'pointer', ...(i === 0 ? { marginTop: 25 } : {}) }}
                      onClick={() => scrollToSection(i)}>
                      {chapter.title}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      default:
    }
  };

  const updateQCM = async (apiType: any, ind: number, i: any) => {
    try {
      const response = await apiServiceNative.put(`/api${apiType}`, {});
      if (response && response.id) {
        setAllBlocks((prevBlocks: any) => {
          const newItems = [...prevBlocks];
          newItems[ind].response_qcm_user = i;
          return newItems;
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const updateQCMText = async (apiType: any, ind: number, i: any, value: any) => {
    try {
      const resp = await apiServiceNative.put(`/api${apiType}`, { response: value });
      if (resp && resp.id) {
        setAllBlocks((prevBlocks: any) => {
          const newItems = [...prevBlocks];
          newItems[ind].response_qcm_text_user = value;
          return newItems;
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateFreeQuestion = async (apiType: any, value: any) => {
    try {
      const resp = await apiServiceNative.put(`/api${apiType}`, { response: value });
    } catch (e) {
      console.error(e);
    }
  };

  const scrollToSection = (ind: any) => {
    const element = document.getElementById(`title-${ind}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <LayoutResultat
      typeScroll="static"
      isWhite={true}
      showLogo={false}
      index={index}
      isNew3={true}
      toBack="/mes-ressources">
      {loading ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}>
          <p />
          Chargement en cours...
        </div>
      ) : (
        <div className={classes.containerEbookParent}>
          <div className={classes.containerEbook}>
            <img src={url} alt="cover" className={classes.coverEbook} />
            <div style={{ marginTop: 70 }}>
              {allBloks.map((block: any, ind: number) => (
                <div
                  style={
                    block?.bloc_template !== 2
                      ? { padding: '40px 0px' }
                      : block?.title_type === 'subtitle'
                      ? { padding: '10px 0px' }
                      : { padding: '40px 0px 15px' }
                  }>
                  {renderTypeBlock(block, ind)}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </LayoutResultat>
  );
};

export default ViewEbook;
