import { Button, Grid, Typography } from '@material-ui/core';
import { ebookBookTemplateActions } from '@orientaction/api-actions';
import ContainerAdmin from '@orientaction/commons/ContainerAdmin';
import MarginContainer from '@orientaction/commons/MarginContainer';
import { ItemGame, Layout } from '@orientaction/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import style from '../style';
import SkeletonList from './skeleton';

const ListEbook = (props: any) => {
  const classes = style();
  const history = useHistory();
  const [list, setList] = useState<any>([]);
  const [options, setOptions] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const getListGame = async () => {
    setLoading(true);
    const response = await apiServiceNative.get('/api/game-list');
    setList(response);
    setLoading(false);
  };

  const getOptions = async () => {
    const response = await apiServiceNative.get('/api/setup-game');
    console.log({ response });
    setOptions(response);
  };

  const deletePagesTemps = async (pages: any) => {
    await apiServiceNative.get(`/api/remove-pages?pageId=${pages}`);
    sessionStorage.removeItem('idPagesTemps');
  };

  const deleteFiles = async (pages: any) => {
    await apiServiceNative.get(`/api/remove-files?fileId=${pages}`);
    localStorage.removeItem('idFILESTemps');
  };

  useEffect(() => {
    (async () => {
      const idPagesTemps: any = sessionStorage.getItem('idPagesTemps');
      if (idPagesTemps) {
        const pages = JSON.parse(idPagesTemps);
        await deletePagesTemps(pages);
      }
      await getListGame();
      await getOptions();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const idFilesTemps: any = localStorage.getItem('idFILESTemps');
      if (idFilesTemps) {
        const files = JSON.parse(idFilesTemps);
        await deleteFiles(files);
      }
    })();
  }, []);

  const callBackDelete = async (isDeleted: boolean) => {
    if (isDeleted) {
      await getListGame();
    }
  };

  const dispatch = useDispatch();

  return (
    <Layout isaddBackground={true} showOverFlow={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item={true} sm="auto">
              <Typography variant="h2" style={{ textTransform: 'none' }}>
                Ebooks{' '}
              </Typography>
            </Grid>
            <Grid item={true} sm="auto">
              <Button
                variant="contained"
                color="primary"
                className={classes.btnPrimary}
                onClick={() => {
                  dispatch(ebookBookTemplateActions.resetValuesEbooks());
                  sessionStorage.clear();
                  history.push('/admin/ebook/create');
                }}
              >
                Créer un nouvel ebook
              </Button>
            </Grid>
          </Grid>
          <span className={classes.separator} />
          {loading && <SkeletonList />}
          <div>
            {!loading &&
              list.map((game: any) => (
                <ItemGame
                  id={game.id}
                  title={game.title}
                  pageNumber={game.pages}
                  status={game.status}
                  callBack={callBackDelete}
                  options={options}
                  permission={game?.authorizations}
                  isEbook={true}
                />
              ))}
          </div>
        </MarginContainer>
      </ContainerAdmin>
    </Layout>
  );
};

export default ListEbook;
