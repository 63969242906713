/* eslint-disable react/no-danger */
import style from './style';
import { Grid } from '@material-ui/core';
import { ReactComponent as Encyclopedie } from '../../../../Asset/svg/encyclopedie.svg';

const ItemEncyclopedie = (props: any) => {
  const classes = style();
  const { text, isEbook = false } = props;
  return (
    <div className={classes.container}>
      <Grid className={classes.containerGrey} container={true}>
        <Grid item={true}>
          <Encyclopedie className={classes.icon} />
        </Grid>
        <Grid item={true} xs={true}>
          {isEbook ? (
            <div className={classes.text} style={{fontFamily: 'Open Sans'}} dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            <div className={classes.text}>{text}</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ItemEncyclopedie;
