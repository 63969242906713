/* eslint-disable prettier/prettier */
/* eslint-disable simple-import-sort/imports */
import './index.css';
import style from '../style';

const ItemBlocks = (props: any) => {
  const classes = style();
  const { number, children, isSelected, handleClick} = props
  return (
    <>
      <div style={{marginTop: 25}} onClick={handleClick}>
        <div className={classes.containerBlock2}>
          <div className={classes.blockNumber}>
            <h3 className={classes.titleItem} style={{margin: 0, fontSize: 15}}>{number}</h3>
          </div>
          <div className={classes.blockContent} style={{border: `1px solid ${isSelected ? '#242424' : 'transparent'}`}}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemBlocks;
