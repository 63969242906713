import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '1px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E4E4F2',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    textAlign: 'center',
  },
  icon: {
    width: 14,
    height: 14,
  },
  iconSmall: {
    width: 12,
    height: 12,
  },
  sectionFlex: {
    display: 'flex',
  },
  sectionFlexContent: {
    display: 'flex',
    padding: '0px 0px',
    borderLeft: '1px solid #E4E4F2',
  },
  select: {
    border: 'none',
    background: 'none',
    fontSize: 14,
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#E4E4F2',
      top: '-6px',
    },
    '& div input': {
      padding: 0,
      width: 35,
      height: 37,
      textAlign: 'center',
      fontSize: 14,
    },
  },
  active: {
    background: '#000a8c38',
  },
  selectLine: {
    border: 'none',
    background: 'none',
    width: 'auto',
    marginLeft: 10,
  },
  textSize: {
    fontSize: 12,
    textWrap: 'nowrap',
  },
}));
