/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
import Page00 from './pages/00';
import Page01 from './pages/01';
import Page02 from './pages/02';
import Page03 from './pages/03';
import Page04 from './pages/04';
import Page05 from './pages/05';
import Page06 from './pages/06';
import Page07 from './pages/07';
import Page08 from './pages/08';
import Page09 from './pages/09';
import Page10 from './pages/10';
import Page11 from './pages/11';
import Page12 from './pages/12';
import Page13 from './pages/13';
import Page14 from './pages/14';
import Page15 from './pages/15';
import Page16 from './pages/16';
import Page17 from './pages/17';
import Page18 from './pages/18';
import Page19 from './pages/19';
import Page20 from './pages/20';
import Page21 from './pages/21';
import Page22 from './pages/22';
import Page23 from './pages/23';
import Page24 from './pages/24';
import Page25 from './pages/25';
import Page26 from './pages/26';
import Page27 from './pages/27';
import Page28 from './pages/28';
import Page29 from './pages/29';
import Page30 from './pages/30';
import Page31 from './pages/31';
import Page32 from './pages/32';
import Page33 from './pages/33';
import Page34 from './pages/34';
import Page35 from './pages/35';
import Page36 from './pages/36';
import Page37 from './pages/37';
import Page38 from './pages/38';
import Page39 from './pages/39';
import Page40 from './pages/40';
import Page41 from './pages/41';
import Page42 from './pages/42';
import Page43 from './pages/43';
import Page44 from './pages/44';
import Page45 from './pages/45';
import { ButtonSomaire, FooterEbook, PageCouverture } from '@orientaction/components';
import { useTheme } from '@material-ui/core/styles';
import { LayoutResultat } from '@orientaction/layouts';
import useStyles from './style';
import { useBook4 } from '@orientaction/hooks_book';
import { useEffect, useState } from 'react';
import { useAlert, useRedirectToRootPage } from '@orientaction/hooks';
import { WithWidth } from '@material-ui/core/withWidth';
import { useLocation, useParams } from 'react-router-dom';
import { isAdminVerify } from '@orientaction/utils';

const FourthEbook = (props: WithWidth) => {
  const theme = useTheme();

  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const [users_permissions_user, setUsers_permissions_user] = useState(0);

  const location = useLocation();

  const [readOnly, setReadonly] = useState(true);

  const { id, index }: any = useParams();

  const classe = useStyles();

  const { width } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const { getMyValueBook, setId, setAll } = useBook4();
  const { goToRootPage } = useRedirectToRootPage();

  const getValue = async (_id: number) => {
    setLoading(true);

    try {
      const response = await getMyValueBook(id);
      console.log({response})

      setUsers_permissions_user(response.data.value.users_permissions_user.id);
      setReadonly(response.data.readOnly);
      setAll(response.data.value);
    } catch (err: any) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getValue(+id);
    setId(+id);
    console.log({id})
  }, [id]);

  return (
    <LayoutResultat
      isWhite={true}
      showLogo={false}
      toBack={
        isAdminVerify()
          ? `/admin/ressources-beneficiaire/${users_permissions_user}`
          : '/mes-ressources'
      }
      index={index}>
      {loading ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}>
          <p />
          Chargement en cours...
        </div>
      ) : (
        <div className={classe.readOnly}>
          {/* <PageCouverture src="couverture4.jpg" /> */}
          <Page00 />
          <Page01 />
          <Page02 />
          <Page03 />
          <Page04 readOnly={readOnly} />
          <Page05 />
          <Page06 />
          <Page07 />
          <Page08 />
          <Page09 />
          <Page10 />
          <Page11 />
          <Page12 />
          <Page13 />
          <Page14 />
          <Page15 />
          <Page16 />
          <Page17 />
          <Page18 readOnly={readOnly} />
          <Page19 readOnly={readOnly} />
          <Page20 />
          <Page21 />
          <Page22 readOnly={readOnly} />
          <Page23 />
          <Page24 />
          <Page25 />
          <Page26 readOnly={readOnly} />
          <Page27 />
          <Page28 />
          <Page29 readOnly={readOnly} />
          <Page30 />
          <Page31 />
          <Page32 />
          <Page33 />
          <Page34 readOnly={readOnly} />
          <Page35 readOnly={readOnly} />
          <Page36 />
          <Page37 />
          <Page38 readOnly={readOnly} />
          <Page39 readOnly={readOnly} />
          <Page40 readOnly={readOnly} />
          <Page41 readOnly={readOnly} />
          <Page42 />
          <Page43 />
          <Page44 />
          <Page45 readOnly={readOnly} />
          <div className={classe.marginFooter}>
            <FooterEbook
              ebookName="l’Étape 4"
              toBack={
                isAdminVerify()
                  ? `/admin/ressources-beneficiaire/${users_permissions_user}`
                  : '/mes-ressources'
              }
            />
          </div>
          <ButtonSomaire href="page-6" />
        </div>
      )}
    </LayoutResultat>
  );
};

export default FourthEbook;
