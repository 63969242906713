/* eslint-disable no-shadow */
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { ReactComponent as Subtract } from '../../../../Asset/svg/subtract.svg';
import { ReactComponent as Subchapter } from '../../../../Asset/svg/sub_chapter.svg';
import style from './style';

interface IItemPage {
  id: string;
  title: string;
  isSelected?: boolean;
  handleClick?: () => void;
  isSubChapter?: boolean;
}

const ItemChapter: FC<IItemPage> = ({
  id,
  title,
  isSelected,
  handleClick,
  isSubChapter = false,
}) => {
  const classes = style();
  return (
    <div
      onClick={handleClick}
      className={`${classes.container} ${
        isSelected ? classes.borderSelected : classes.borderTransparent
      } `}
      style={{ paddingTop: 7, paddingBottom: 7 }}>
      <div className={classes.container2}>
        <div
          className={classes.container3}
          style={isSubChapter ? { background: 'transparent', padding: 0 } : {}}>
          {isSubChapter ? (
            <Subchapter style={{ width: 40, height: 5 }} />
          ) : (
            <Subtract style={{ width: 20, height: 20 }} />
          )}
        </div>
      </div>
      <Typography variant="h4" className={classes.title} style={isSubChapter ? { fontSize: 11 } : {}}>
        {title}
      </Typography>
    </div>
  );
};

export default ItemChapter;
