import style from './style';

const ItemCitation = (props: any) => {
  const classes = style();
  const { text, autor, isEbook = false } = props;
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.text} style={isEbook ? { fontFamily: 'Open Sans' } : {}}>
          <span className={classes.dot}>“</span>
          <span style={{...({ margin: '0px 3px 0px 7px' }), ...(isEbook ? { fontFamily: 'Open Sans', fontWeight: 600, lineHeight: 1.9 } : {})}}>{text}</span>
          <span className={classes.dot}>“</span>
        </div>
        <div className={classes.autor} style={isEbook ? { fontFamily: 'Open Sans', fontWeight: 700 } : {}}>{autor}</div>
      </div>
    </div>
  );
};

export default ItemCitation;
