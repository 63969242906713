import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '15px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  gridCenterVerticaly: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    marginLeft: '10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: 1,
  },
  title2: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    color: 'white',
    marginTop: '5px',
  },
  img: {
    width: '25%',
  },
  container2: {
    display: 'flex',
    alignItems: 'center',
  },
  container3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 10px',
    background: '#464646',
    borderRadius: 5,
  },
  borderSelected: {
    border: '1px solid #B0B5FF',
    borderRadius: 5,
  },
  borderTransparent: {
    border: '1px solid transparent',
    borderRadius: 5,
  },
  borderSelectedLastPage: {
    border: '1px solid #E63C3C',
    borderRadius: 5,
  },
}));
