import { IconButton } from '@material-ui/core';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import TestMainImage from '@orientaction/components/Admin/Test/Create/TestMainInfo/LeftImage/TestMainImage';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Broder } from '../../../../Asset/svg/border_white.svg';
import { ReactComponent as Close } from '../../../../Asset/svg/close.svg';
import ModalEditImg from '../ModalEditImg';
import style from '../ModelPage/style';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span
        style={{
          fontSize: 26,
          margin: 8,
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        +
      </span>
      <span style={{ fontSize: 16 }}>Importer une image</span>
    </div>
  );
};
interface IEditorImg {
  width: number;
  height: number;
  handleChangeImg?: (infoImg: any) => void;

  defaultUrl?: string;
  isFullHeight?: boolean;
  handleFile?: (file: any) => void;
  keyComponent?: any;
}
const EditorImg: FC<IEditorImg> = ({
  width,
  height,
  handleChangeImg,
  defaultUrl = null,
  isFullHeight = false,
  handleFile,
  keyComponent,
}) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [position, setPosition] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [imageDimensions, setImageDimensions] = useState<any>({});
  const handleFileUrlChange = (urlC: string) => {
    // dispatch(gameBookTemplateActions.updateField('tempImage', urlC));
    setUrl(urlC);
    if (handleChangeImg) {
      handleChangeImg({
        url,
        position,
        file,
      });
    }
    if (urlC) {
      const img = new Image();
      img.src = urlC;

      img.onload = () => {
        setImageDimensions({
          height: img.height,
          width: img.width,
        });
      };
      img.onerror = (err) => {
        console.log('img error');
        console.error(err);
      };
    }
  };
  const handleFileChange = (fileC: any) => {
    setFile(fileC);
    if (handleFile) {
      handleFile(fileC);
    }

    if (handleChangeImg) {
      handleChangeImg({
        url,
        position,
        file,
      });
    }
  };

  const handleDeleteFile = (urlID?: any) => {
    /*  if (urlID) {
      apiServiceNative.delete(`/api/upload/files/${urlID?.id}`);
    } */
    setFile(null);
    setUrl('');
    dispatch(gameBookTemplateActions.updateField('illustration', null));
    // dispatch(gameBookTemplateActions.updateField('tempImage', null));
    if (handleChangeImg) {
      handleChangeImg({
        url,
        position,
        file,
      });
    }
  };

  useEffect(() => {
    if (defaultUrl) {
      setUrl(defaultUrl);
    } else {
      setUrl('');
    }
  }, [defaultUrl]);

  useEffect(() => {
    if (imageDimensions.height && imageDimensions.width) {
      setOpen(true);
    }
  }, [imageDimensions]);

  const openImage = () => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = (err) => {
      console.log('img error');
      console.error(err);
    };
    setOpen(true);
  };

  return (
    <>
      <TestMainImage
        fileUrl={url}
        handleFileUrlChange={handleFileUrlChange}
        handleFileChange={handleFileChange}
        placeholder={placeholder}
        pathPrefix="tests"
        sectionTop={true}
        fullSize={true}
        useForModel={true}
        position={position}
        isFullHeight={isFullHeight}
        useForGame={true}
        keyComponent={keyComponent || Math.random()}
      />
      {url && (
        <div className={classes.iconBtnImage} key={url}>
          <IconButton
            aria-label="delete"
            style={{ background: '#E63C3C' }}
            onClick={() => {
              handleDeleteFile(url);
            }}
          >
            <Close className={classes.icon} />
          </IconButton>
          <IconButton
            aria-label="delete"
            style={{ background: '#000A8C', marginTop: 15 }}
            onClick={() => openImage()}
          >
            <Broder className={classes.icon} />
          </IconButton>
        </div>
      )}
      <ModalEditImg
        open={open}
        fileUrl={url}
        fileOrigin={file}
        width={width}
        height={height}
        imgHeightB={imageDimensions.height as any}
        imgWidthB={imageDimensions.width}
        handleClose={() => setOpen(false)}
        handlePosition={(pos) => setPosition(pos)}
        handleUrl={(u: any) => setUrl(u)}
      />
    </>
  );
};

export default EditorImg;
