import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  editArea: {
    fontWeight: 'bold',
    width: 'calc(100% - 10px)',
    padding: '5px',
    borderRadius: '0px',
    marginBottom: '5px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #ccc',
    resize: 'none',
    fontFamily: 'Poppins',
  },
}));
