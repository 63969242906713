import { Button, Grid } from '@material-ui/core';
import { ebookBookTemplateActions, gameBookTemplateActions } from '@orientaction/api-actions';
import ContainerAdmin from '@orientaction/commons/ContainerAdmin';
import MarginContainer from '@orientaction/commons/MarginContainer';
import { ItemEbook, ItemGame, Layout } from '@orientaction/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import style from '../style';
import SkeletonList from './skeleton';

const ListGame = (props: any) => {
  const classes = style();
  const history = useHistory();
  const [list, setList] = useState<any>([]);
  const [listEbook, setListEbook] = useState<any>([]);
  const [options, setOptions] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(() => {
    const fromEbook = sessionStorage.getItem('fromEbook');
    return fromEbook ? 'ebook' : 'game';
  });

  const getListGame = async () => {
    setLoading(true);
    const response = await apiServiceNative.get('/api/game-list');
    setList(response);
    setLoading(false);
  };

  const getListEbooks = async () => {
    setLoading(true);
    const response = await apiServiceNative.get('/api/ebook-list');
    setListEbook(response);
    setLoading(false);
  };

  const getOptions = async () => {
    const response = await apiServiceNative.get('/api/setup-game');

    setOptions(response);
  };

  const deletePagesTemps = async (pages: any) => {
    await apiServiceNative.get(`/api/remove-pages?pageId=${pages}`);
    sessionStorage.removeItem('idPagesTemps');
  };

  const deleteFiles = async (pages: any) => {
    await apiServiceNative.get(`/api/remove-files?fileId=${pages}`);
    localStorage.removeItem('idFILESTemps');
  };

  useEffect(() => {
    (async () => {
      const idPagesTemps: any = sessionStorage.getItem('idPagesTemps');
      if (idPagesTemps) {
        const pages = JSON.parse(idPagesTemps);
        await deletePagesTemps(pages);
      }
      await getListGame();
      await getOptions();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const idFilesTemps: any = localStorage.getItem('idFILESTemps');
      if (idFilesTemps) {
        const files = JSON.parse(idFilesTemps);
        await deleteFiles(files);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (activeTab !== 'game') {
        setLoading(true);
        await getListEbooks();
        sessionStorage.clear();
      }
    })();
  }, [activeTab]);

  const callBackDelete = async (isDeleted: boolean) => {
    if (isDeleted) {
      await getListGame();
    }
  };

  const callBackDeleteBooks = async (isDeleted: boolean) => {
    if (isDeleted) {
      await getListEbooks();
    }
  };

  const dispatch = useDispatch();

  return (
    <Layout isaddBackground={true} showOverFlow={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <Grid container={true} className={classes.separatorGrid}>
            <Grid item={true}>
              <Button
                className={classes.btnTab}
                style={activeTab === 'game' ? { background: '#000A8C', color: 'white' } : {}}
                onClick={() => setActiveTab('game')}
                size="large"
              >
                Serious games
              </Button>
              <Button
                className={classes.btnTab}
                style={activeTab === 'ebook' ? { background: '#000A8C', color: 'white' } : {}}
                onClick={() => setActiveTab('ebook')}
                size="large"
              >
                E-books
              </Button>
            </Grid>
          </Grid>
          {activeTab === 'game' && (
            <>
              <Grid
                container={true}
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item={true} sm="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnPrimary}
                    style={{ margin: '30px 0px 20px' }}
                    onClick={() => {
                      history.push('/admin/game/create');
                      dispatch(gameBookTemplateActions.resetValues());
                      sessionStorage.clear();
                    }}
                  >
                    Créer un nouveau jeu
                  </Button>
                </Grid>
              </Grid>
              {loading && <SkeletonList />}
              <div>
                {!loading &&
                  list.map((game: any) => (
                    <ItemGame
                      id={game.id}
                      title={game.title}
                      pageNumber={game.pages}
                      status={game.status}
                      callBack={callBackDelete}
                      options={options}
                      permission={game?.authorizations}
                    />
                  ))}
              </div>
            </>
          )}
          {activeTab === 'ebook' && (
            <>
              <Grid
                container={true}
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item={true} sm="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnPrimary}
                    style={{ margin: '30px 0px 20px' }}
                    onClick={() => {
                      dispatch(ebookBookTemplateActions.resetValuesEbooks());
                      sessionStorage.clear();
                      history.push('/admin/ebook/create');
                    }}
                  >
                    Créer un nouvel ebook
                  </Button>
                </Grid>
              </Grid>

              {loading && <SkeletonList />}
              <div>
                {!loading &&
                  listEbook.map((b: any) => (
                    <ItemEbook
                      id={b.id}
                      title={b.title}
                      status={b.status}
                      callBack={callBackDeleteBooks}
                      options={options}
                      permission={b?.authorizations}
                    />
                  ))}
              </div>
            </>
          )}
        </MarginContainer>
      </ContainerAdmin>
    </Layout>
  );
};

export default ListGame;
