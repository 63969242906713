import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textArea: {
      width: '99%',
      height: '80px',
      resize: 'vertical',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
    textAreaNotBorder: {
      width: '99%',
      display: 'flex',
      border: 'none',
    },
    largeTextField: {
      minHeight: '150px',
    },
    litleTextField: {
      height: '40px',
    },
    veryLitleTextField: {
      height: '16px',
    },
    editArea: {
      width: 'calc(100% - 20px)',
      fontWeight: 'normal',
      padding: '10px',
      fontFamily: 'Open Sans',
      color: 'black',
      background: '#F1F1F1',
      borderRadius: 5,
      fontSize: 16,
      border: 'none',
      outline: 'none',
    },
  })
);
