const INITIAL_STATE: any = {
  values: {
    // values
    title: '',
    couvTemp: '',
  },
};

const ebookBookTemplateReducer = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'UPDATE_FIELD_EBOOK':
      return {
        ...state,
        values: { ...state.values, [action.payload.field]: action.payload.value },
      };

    case 'SET_ALL_VALUES_EBOOK':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };

    case 'RESET_VALUES_EBOOK':
      return {
        values: {
          title: '',
          couvTemp: '',
        },
      };

    default:
      return state;
  }
};

export default ebookBookTemplateReducer;
