/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable react/button-has-type */

import { gameBookTemplateActions } from '@orientaction/api-actions';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './TextEditor.css';

interface ITextEditor {
  isSmall?: boolean;
  handleActiveStyles?: (style: any) => void;
  handleFocused?: (focused: boolean) => void;
  styleEditor?: any;
  linkTitleE?: string;
  linkUrlE?: string;
  showLinkPopupE?: boolean;
  reinit?: boolean;
  field?: string;
  defaultValue?: any;
  isEbook?: boolean;
  handleChange?: (content: any) => void;
  pulseMove?: number;
  placeholder?: string;
  position?: string;
  handlePosition?: (position: any) => void;
}

const TextEditor: React.FC<ITextEditor> = ({
  isSmall = false,
  handleActiveStyles,
  handleFocused,
  styleEditor,
  linkTitleE,
  linkUrlE,
  showLinkPopupE,
  reinit,
  field,
  defaultValue = '',
  isEbook = false,
  handleChange,
  pulseMove = -1,
  placeholder = 'Cliquer pour ajouter du texte',
  position = '',
  handlePosition,
}) => {
  const [content, setContent] = useState<string>('');
  const [contentC, setContentC] = useState<string>('');
  const [activeStyles, setActiveStyles] = useState<Record<string, any>>({});
  const [showLinkPopup, setShowLinkPopup] = useState<boolean>(true);
  const [linkTitle, setLinkTitle] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [lineHeightState, setLineHeightState] = useState<string>('1.75');
  const [alignment, setAlignment] = useState<string>(position);
  const editorRef = useRef<HTMLDivElement>(null);
  const [first, setFirst] = useState<boolean>(false);
  const styleToggle = [
    'bold',
    'italic',
    'underline',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'insertUnorderedList',
    'insertOrderedList',
  ];

  const savedSelection = useRef<Range | null>(null);
  const dispatch = useDispatch();

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (savedSelection.current && selection) {
      selection.removeAllRanges();
      selection.addRange(savedSelection.current);
    }
  };

  const applyStyle = (styleCommand: string, arg?: string) => {
    saveSelection();

    if (styleCommand === 'reset') {
      document.execCommand('removeFormat', false, '');
      document.execCommand('unlink', false, '');
      if (editorRef.current) {
        editorRef.current.style.lineHeight = '';
      }
      setActiveStyles({});
      return;
    }

    if (styleCommand === 'lineHeight') {
      if (editorRef.current) {
        restoreSelection();

        setLineHeightState(arg as any);
        const selection = window.getSelection();
        const range = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
        const selectedText = range ? range.toString() : '';

        if (selectedText && range) {
          const span = document.createElement('span');
          span.style.lineHeight = arg || '';
          span.style.display = 'inline-block';
          span.style.width = '100%';
          span.style.marginTop =
            arg === '1.15'
              ? '0.05em'
              : arg === '1.75'
              ? '0.075em'
              : arg === '2'
              ? '0.1em'
              : arg === '3'
              ? '0.2em'
              : '0';
          span.style.marginBottom =
            arg === '1.15'
              ? '0.05em'
              : arg === '1.75'
              ? '0.075em'
              : arg === '2'
              ? '0.1em'
              : arg === '3'
              ? '0.2em'
              : '0';

          try {
            // Check if the range can be surrounded
            if (range.startContainer.nodeType === Node.TEXT_NODE) {
              range.surroundContents(span);
            } else if (range.startContainer === range.endContainer) {
              // When selection is within a single container but not text node
              const wrapperRange = document.createRange();
              wrapperRange.selectNodeContents(range.startContainer);
              wrapperRange.surroundContents(span);
            } else {
              // For complex cases, handle partial selections
              console.warn('Complex selection detected, handling each part.');
              const contents = range.extractContents();
              const children = contents.childNodes;

              for (let i = 0; i < children.length; i++) {
                const node = children[i];
                if (node.nodeType === Node.TEXT_NODE) {
                  const spanClone = span.cloneNode() as HTMLSpanElement;
                  spanClone.textContent = node.textContent;
                  range.insertNode(spanClone);
                } else {
                  range.insertNode(node);
                }
              }
            }
          } catch (e) {
            console.log('Cannot surround contents:', e);

            const containerDiv = document.createElement('div');
            containerDiv.setAttribute('data-align-parent', 'true');
            containerDiv.style.height = 'auto';
            containerDiv.style.lineHeight = arg || '';
            containerDiv.style.display = 'inline-block';
            containerDiv.style.width = '100%';
            containerDiv.style.marginTop =
              arg === '1.15'
                ? '0.05em'
                : arg === '1.75'
                ? '0.075em'
                : arg === '2'
                ? '0.1em'
                : arg === '3'
                ? '0.2em'
                : '0';
            containerDiv.style.marginBottom =
              arg === '1.15'
                ? '0.05em'
                : arg === '1.75'
                ? '0.075em'
                : arg === '2'
                ? '0.1em'
                : arg === '3'
                ? '0.2em'
                : '0';

            const contents = range.extractContents();

            const removeLineHeightStyles = (node: Node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as HTMLElement;
                element.style.lineHeight = '';
                Array.from(element.children).forEach(removeLineHeightStyles);
              }
            };

            Array.from(contents.childNodes).forEach(removeLineHeightStyles);

            containerDiv.appendChild(contents);

            range.insertNode(containerDiv);

            const newRange = document.createRange();
            newRange.selectNode(containerDiv);
            if (selection) {
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }

          setActiveStyles({ ...activeStyles, lineHeight: arg });
        }
      }
    } else if (styleCommand.startsWith('justify')) {
      if (activeStyles[styleCommand]) {
        document.execCommand('justifyLeft', false, '');
        setActiveStyles({ ...activeStyles, [styleCommand]: false });
      } else {
        document.execCommand(styleCommand, false, arg || '');
        setActiveStyles({
          ...activeStyles,
          justifyLeft: false,
          justifyCenter: false,
          justifyRight: false,
          [styleCommand]: true,
        });
      }
    } else if (styleCommand === 'insertUnorderedList' || styleCommand === 'insertOrderedList') {
      if (activeStyles[styleCommand]) {
        document.execCommand('insertParagraph', false, '');
        setActiveStyles({ ...activeStyles, [styleCommand]: false });
      } else {
        document.execCommand(styleCommand, false, arg || '');
        setActiveStyles({
          ...activeStyles,
          insertUnorderedList: false,
          insertOrderedList: false,
          [styleCommand]: true,
        });
      }
    } else if (styleCommand === 'formatBlock' && arg) {
      removeCurrentBlockFormat();
      document.execCommand(styleCommand, false, arg);
      setActiveStyles({ ...activeStyles, formatBlock: arg });
      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(arg.toLowerCase())) {
        const elements = editorRef.current?.getElementsByTagName(arg.toLowerCase()) as any;
        if (elements) {
          for (const element of Array.from(elements) as any) {
            element.style.fontWeight = 'normal';
          }
        }
      }
    } else {
      document.execCommand(styleCommand, false, arg || '');
      updateActiveStyles();
    }

    restoreSelection();
  };

  const removeLineHeightSpans = (element: HTMLElement) => {
    const spans = element.querySelectorAll('span[style*="line-height"]');
    spans.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent?.insertBefore(span.firstChild, span);
      }
      parent?.removeChild(span);
    });
  };

  const removeCurrentBlockFormat = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;
    let parentBlock = container.parentElement;
    while (parentBlock && !parentBlock.matches('div, p, h1, h2, h3, h4, h5, h6')) {
      parentBlock = parentBlock.parentElement;
    }
    if (parentBlock && parentBlock.matches('h1, h2, h3, h4, h5, h6')) {
      const fragment = document.createDocumentFragment();
      while (parentBlock.firstChild) {
        fragment.appendChild(parentBlock.firstChild);
      }
      parentBlock.parentNode?.replaceChild(fragment, parentBlock);
    }
  };

  const resetSelectedStyle = () => {
    if (editorRef.current) {
      saveSelection();

      document.execCommand('removeFormat', false, '');
      document.execCommand('unlink', false, '');

      const parentDiv = editorRef.current.querySelector('div[data-align-parent="true"]');
      if (parentDiv) {
        const textContent = parentDiv.textContent || '';
        parentDiv.innerHTML = textContent;
      } else {
        const textContent = editorRef.current.textContent || '';
        editorRef.current.innerHTML = `<div data-align-parent="true" style="height: auto;">${textContent}</div>`;
      }

      editorRef.current.style.justifyContent = 'flex-start';
      setAlignment('flex-start');
      setActiveStyles({});
      updateActiveStyles();

      restoreSelection();
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  const getCurrentLineHeight = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return '1.75';
    }

    const range = selection.getRangeAt(0);
    let node = range.commonAncestorContainer as any;

    while (node && node.nodeType === Node.TEXT_NODE) {
      node = node.parentElement;
    }

    if (node) {
      let computedStyle = window.getComputedStyle(node);

      while (computedStyle.lineHeight === 'normal' || computedStyle.lineHeight === 'auto') {
        node = node.parentElement;
        if (!node) break;
        computedStyle = window.getComputedStyle(node);
      }

      if (computedStyle.lineHeight !== 'normal' && computedStyle.lineHeight !== 'auto') {
        return computedStyle.lineHeight;
      }
    }

    return '1.75';
  };

  const getCurrentAlignment = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return 'flex-start';
    const range = selection.getRangeAt(0);
    const { parentElement } = range.commonAncestorContainer;

    if (parentElement) {
      const computedStyle = window.getComputedStyle(parentElement);
      return computedStyle.justifyContent;
    }
    return 'flex-start';
  };

  const updateActiveStyles = () => {
    const styles: Record<string, boolean | string> = {
      bold: document.queryCommandState('bold'),
      italic: document.queryCommandState('italic'),
      underline: document.queryCommandState('underline'),
      justifyLeft: document.queryCommandState('justifyLeft'),
      justifyCenter: document.queryCommandState('justifyCenter'),
      justifyRight: document.queryCommandState('justifyRight'),
      insertOrderedList: document.queryCommandState('insertOrderedList'),
      insertUnorderedList: document.queryCommandState('insertUnorderedList'),
      link: document.queryCommandState('createLink'),
      formatBlock: document.queryCommandValue('formatBlock').toLowerCase(),
      lineHeight: getCurrentLineHeight(),
      alignment: getCurrentAlignment(),
    };

    setActiveStyles(styles);
  };

  const ensureTextInsideParent = () => {
    if (editorRef.current) {
      let parentDiv = editorRef.current.querySelector('div[data-align-parent="true"]') as any;
      editorRef.current.style.justifyContent = alignment;

      if (!parentDiv) {
        parentDiv = document.createElement('div');
        parentDiv.setAttribute('data-align-parent', 'true');
        parentDiv.style.height = 'auto';

        while (editorRef.current.firstChild) {
          parentDiv.appendChild(editorRef.current.firstChild);
        }

        editorRef.current.appendChild(parentDiv);
      } else {
        Array.from(editorRef.current.childNodes).forEach((node) => {
          if (node !== parentDiv) {
            parentDiv.appendChild(node);
            if (editorRef && editorRef.current) {
              const range = document.createRange();
              const selection = window.getSelection();
              range.setStart(editorRef.current, editorRef.current.childNodes.length);
              range.collapse(true);
              selection?.removeAllRanges();
              selection?.addRange(range);
              // Focus sur le div éditable
              editorRef?.current.focus();
            }
          }
        });
      }
    }
  };

  const handleContentChange = () => {
    if (editorRef.current) {
      setContent(editorRef.current.innerHTML);
      setContentC(
        editorRef.current.querySelector('div[data-align-parent="true"]')?.innerHTML as any,
      );

      ensureTextInsideParent();
      if (handleChange) {
        handleChange(editorRef.current.innerHTML);
      }
      if (field) {
        dispatch(gameBookTemplateActions.updateField(field, editorRef.current.innerHTML));
      }
    }
    updateActiveStyles();
  };

  const handleInsertLink = (e: any) => {
    restoreSelection();
    e.stopPropagation();

    if (linkTitle && linkUrl) {
      const linkHTML = `<a href="${linkUrl}" target="_blank">${linkTitle}</a>`;
      document.execCommand('insertHTML', false, linkHTML);
    }
    if (handleFocused) handleFocused(true);
    setShowLinkPopup(false);
    setLinkTitle('');
    setLinkUrl('');
    handleContentChange();
    setActiveStyles({ ...activeStyles, link: false });
  };

  useEffect(() => {
    if (!editorRef.current) return;

    const handleBackspace = (event: any) => {
      if (event.key === 'Backspace') {
        event.preventDefault();
        if (editorRef.current?.innerText.trim().length === 0) {
          editorRef.current.innerHTML = '&#8203;';
        } else {
          document.execCommand('delete', false);
        }
      }
    };

    const handleEnter = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const selection = window.getSelection();
        if (!selection?.rangeCount) return;

        const range = selection.getRangeAt(0);
        range.deleteContents();

        const br = document.createElement('br');
        range.insertNode(br);

        range.setStartAfter(br);
        range.setEndAfter(br);

        selection.removeAllRanges();
        selection.addRange(range);

        handleContentChange();
      }
    };

    const handleSelectionChange = () => {
      updateActiveStyles();
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    editorRef.current.addEventListener('keydown', handleBackspace);
    editorRef.current.addEventListener('keydown', handleEnter);

    editorRef.current.addEventListener('input', () => {
      handleContentChange();
    });

    updateActiveStyles();

    // Clean up function to remove event listener
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', handleBackspace);
        editorRef.current.removeEventListener('keydown', handleEnter);
      }
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    if (styleEditor) {
      if (styleEditor.styleCommand === 'reset') {
        resetSelectedStyle();
      } else if (styleToggle.includes(styleEditor.styleCommand)) {
        toggleStyle(styleEditor.styleCommand);
      } else {
        applyStyle(styleEditor.styleCommand, styleEditor.arg);
      }
    }
  }, [styleEditor]);

  useEffect(() => {
    if (showLinkPopupE === undefined || showLinkPopupE === null) {
      return;
    }
    saveSelection();
    setShowLinkPopup(!showLinkPopup);
  }, [showLinkPopupE]);

  useEffect(() => {
    if (handleActiveStyles) {
      handleActiveStyles(activeStyles);
    }
  }, [activeStyles]);

  useEffect(() => {
    if (editorRef.current && !first) {
      editorRef.current.innerHTML = defaultValue;

      const parentDiv = editorRef.current.querySelector('div[data-align-parent="true"]') as any;
      if (!parentDiv) {
        const newParentDiv = document.createElement('div');
        newParentDiv.setAttribute('data-align-parent', 'true');
        newParentDiv.style.height = 'auto';
        const { lastChild } = newParentDiv;
        const emptySpace = document.createElement('br');

        if (lastChild && lastChild.nodeName !== 'BR') {
          newParentDiv.appendChild(emptySpace);
        }
        // Valeur par défaut pour l'alignement

        while (editorRef.current.firstChild) {
          newParentDiv.appendChild(editorRef.current.firstChild);
        }

        editorRef.current.appendChild(newParentDiv);
        setAlignment('flex-start');
      } else {
        const justifyContent = editorRef.current.style.justifyContent || 'flex-start';
        editorRef.current.style.justifyContent = justifyContent;
        setAlignment(justifyContent);
      }

      setContent(defaultValue);
      updateActiveStyles();
      saveSelection();
    }
  }, [defaultValue, first]);

  useEffect(() => {
    if (editorRef.current && pulseMove >= 0) {
      editorRef.current.innerHTML = defaultValue;
      setShowLinkPopup(false);
    }
  }, [pulseMove]);

  const toggleStyle = (styleCommand: string) => {
    if (activeStyles[styleCommand]) {
      document.execCommand(styleCommand, false, 'false');
      setActiveStyles({ ...activeStyles, [styleCommand]: false });
    } else {
      document.execCommand(styleCommand, false, 'true');
      setActiveStyles({ ...activeStyles, [styleCommand]: true });
    }
  };

  const wrapContentWithAlignment = (alignmentx: string) => {
    if (editorRef.current) {
      const parentDiv = editorRef.current.querySelector('div[data-align-parent="true"]') as any;
      editorRef.current.style.justifyContent = alignmentx;
      if (parentDiv) {
        // Update the existing parent's alignment style

        editorRef.current.style.justifyContent = alignmentx;
      } else {
        const newParentDiv = document.createElement('div');
        newParentDiv.setAttribute('data-align-parent', 'true');
        newParentDiv.style.height = 'auto';

        while (editorRef.current.firstChild) {
          newParentDiv.appendChild(editorRef.current.firstChild);
        }

        editorRef.current.appendChild(newParentDiv);
        editorRef.current.style.justifyContent = alignmentx;
      }
    }
  };

  const handleAlignmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAlignment = e.target.value;
    setAlignment(newAlignment);
    wrapContentWithAlignment(newAlignment);
    updateActiveStyles();

    if (editorRef.current) {
      const newParentDiv = editorRef.current.querySelector('div[data-align-parent="true"]') as any;
      if (newParentDiv) {
        const { lastChild } = newParentDiv;
        const emptySpace = document.createElement('br');

        if (lastChild && lastChild.nodeName !== 'BR') {
          newParentDiv.appendChild(emptySpace);
        }
      }
    }

    handleContentChange();
  };

  useEffect(() => {
    setAlignment(position);
  }, [position]);

  useEffect(() => {
    if (handlePosition) {
      handlePosition(alignment);
    }
    wrapContentWithAlignment(alignment);
    updateActiveStyles();
    handleContentChange();
  }, [alignment]);

  return (
    <div className="editor-container" style={isEbook ? { height: 'auto' } : {}}>
      <div className="toolbar">
        <button className={activeStyles.bold ? 'active' : ''} onClick={() => toggleStyle('bold')}>
          Gras
        </button>
        <button
          className={activeStyles.italic ? 'active' : ''}
          onClick={() => toggleStyle('italic')}
        >
          Italique
        </button>
        <button
          className={activeStyles.underline ? 'active' : ''}
          onClick={() => toggleStyle('underline')}
        >
          Souligné
        </button>
        <button
          className={activeStyles.justifyLeft ? 'active' : ''}
          onClick={() => toggleStyle('justifyLeft')}
        >
          Aligner à gauche
        </button>
        <button
          className={activeStyles.justifyCenter ? 'active' : ''}
          onClick={() => toggleStyle('justifyCenter')}
        >
          Centrer
        </button>
        <button
          className={activeStyles.justifyRight ? 'active' : ''}
          onClick={() => toggleStyle('justifyRight')}
        >
          Aligner à droite
        </button>
        <button
          className={activeStyles.insertUnorderedList ? 'active' : ''}
          onClick={() => toggleStyle('insertUnorderedList')}
        >
          Bullet Points
        </button>
        <button
          className={activeStyles.insertOrderedList ? 'active' : ''}
          onClick={() => toggleStyle('insertOrderedList')}
        >
          Numérotation
        </button>

        <select value={lineHeightState} onChange={(e) => applyStyle('lineHeight', e.target.value)}>
          <option value="1.15">1</option>
          <option value="1.75">1.15</option>
          <option value="2">1.5</option>
          <option value="3">2</option>
        </select>

        <select value={alignment} onChange={handleAlignmentChange}>
          <option value="flex-start">Alignement Haut</option>
          <option value="center">Alignement Milieu</option>
          <option value="flex-end">Alignement Bas</option>
        </select>

        <button
          onClick={() => {
            saveSelection();
            setShowLinkPopup(true);
          }}
          style={{ display: 'none' }}
        >
          Insérer un lien
        </button>
        <button onClick={resetSelectedStyle}>Réinitialiser le style sélectionné</button>
      </div>
      <div
        ref={editorRef}
        contentEditable={true}
        placeholder={placeholder}
        className={`editable-area-not-choice ${
          contentC === '' || contentC === '\u200B' ? 'empty' : ''
        }`}
        onInput={handleContentChange}
        style={{
          ...{
            minHeight: isSmall ? 156 : 334,
            lineHeight: '1.75',
            display: 'flex',
            flexDirection: 'column',
          },
          ...(isEbook ? { outline: 'none', border: 'none', borderRadius: 0 } : {}),
        }}
        onClick={(e) => {
          setFirst(true);
          if (!isEbook) {
            e.stopPropagation();
          }
          if (handleFocused) handleFocused(true);
        }}
        onFocus={() => {
          if (handleFocused) handleFocused(true);
          if (handleActiveStyles) handleActiveStyles(activeStyles);
          if (handlePosition) handlePosition(alignment);
        }}
        onChange={(e) => {
          if (handleFocused) handleFocused(true);
        }}
        onPaste={handlePaste}
      />

      <button
        onClick={() => {
          saveSelection();
          setShowLinkPopup(true);
        }}
        style={{ display: 'none' }}
      >
        Insérer un lien
      </button>
      <button style={{ display: 'none' }} onClick={resetSelectedStyle}>
        Réinitialiser le style sélectionné
      </button>

      {showLinkPopup && (
        <div
          className="link-popup"
          onClick={(e) => {
            if (!isEbook) {
              e.stopPropagation();
            }
          }}
          onFocus={() => {
            if (handleFocused) handleFocused(true);
          }}
        >
          <input
            type="text"
            value={linkTitle}
            onChange={(e) => setLinkTitle(e.target.value)}
            placeholder="Entrer le titre du lien"
            onFocus={() => {
              if (handleFocused) handleFocused(true);
            }}
          />
          <input
            type="text"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            placeholder="Entrer l'URL du lien"
            onFocus={() => {
              if (handleFocused) handleFocused(true);
            }}
          />
          <button style={{ marginRight: 2 }} onClick={handleInsertLink}>
            Insérer
          </button>
          <button
            onClick={() => {
              setShowLinkPopup(false);
              setLinkTitle('');
              setLinkUrl('');
            }}
          >
            Annuler
          </button>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
