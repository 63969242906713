import { combineReducers } from 'redux';
import { RootState } from '../store';
import book1Reducer from './Book/Book1';
import book2Reducer from './Book/Book2';
import book3Reducer from './Book/Book3';
import book4Reducer from './Book/Book4';
import book5Reducer from './Book/Book5';
import stateBookIntro from './Book/BookIntro';
import stateMajorTest from './MajorTest';
import adminUserReducer from './adminUser';
import alertReducer from './alert';
import apiReducer from './api';
import appReducer from './app';
import authReducer from './auth';
import stateCategoryTest from './categoryTest';
import languageReducer from './language';

import stateCompass from './compass';
import gameBookTemplateReducer from './gameTemplate';
import ebookBookTemplateReducer from './ebookTemplate';
import stateMyResultOfTest from './myResultOfTest';
import stateResultOfTest from './resultOfTest';
import stateStopRender from './stopRender';

const createRootReducer = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  combineReducers({
    api: apiReducer,
    language: languageReducer,
    auth: authReducer,
    app: appReducer,
    alert: alertReducer,
    bookIntro: stateBookIntro,
    book1: book1Reducer,
    book2: book2Reducer,
    book3: book3Reducer,
    book4: book4Reducer,
    book5: book5Reducer,
    compass: stateCompass,
    adminUser: adminUserReducer,
    categoryTest: stateCategoryTest,
    majorTest: stateMajorTest,
    stopRender: stateStopRender,
    resultOfTest: stateResultOfTest,
    myResultOfTest: stateMyResultOfTest,
    gameBookTemplate: gameBookTemplateReducer,
    ebookBookTemplate: ebookBookTemplateReducer,
  });

const rootReducer = createRootReducer();

export default rootReducer;

export const apiState = (state: RootState) => state.api;
export const authState = (state: RootState) => state.auth;
export const appState = (state: RootState) => state.app;
export const appAlert = (state: RootState) => state.alert;
export const appIntro = (state: RootState) => state.bookIntro;
export const appBook1 = (state: RootState) => state.book1;
export const appBook2 = (state: RootState) => state.book2;
export const appBook3 = (state: RootState) => state.book3;
export const appBook4 = (state: RootState) => state.book4;
export const appBook5 = (state: RootState) => state.book5;
export const appMajorTest = (state: RootState) => state.majorTest;
export const appStopRender = (state: RootState) => state.stopRender;
export const appCategoryTest = (state: RootState) => state.categoryTest;
export const adminUser = (state: RootState) => state.adminUser;
export const appResultOfTest = (state: RootState) => state.resultOfTest;
export const appMyResultOfTest = (state: RootState) => state.myResultOfTest;
export const appCompass = (state: RootState) => state.compass;
export const appLanguage = (state: RootState) => state.language.code;
export const gameBookValues = (state: RootState) => state.gameBookTemplate.values;
export const ebookBookValues = (state: RootState) => state.ebookBookTemplate.values;

/**
 * used by selectors
 * @param state
 * @param path
 * @param [errorMessageIfNotFound]
 * @returns {*}
 */

export const getData = (state: RootState, path: string, errorMessageIfNotFound?: any) => {
  let data;

  try {
    if (typeof state === 'function') {
      throw new Error(
        'The state parameter must not be a function. The error is usually the usage of getState instead of getState(). Path is',
      );
    }

    data = path.split('.').reduce((res: any, prop) => res[prop], state);

    if (errorMessageIfNotFound && data == null) {
      throw new Error(errorMessageIfNotFound);
    }
  } catch (error) {
    return null;
  }
  return data;
};
