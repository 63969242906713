import { gameBookTemplateActions } from '@orientaction/api-actions';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import style from './style';

interface IEditorEncyclopedie {
  handleChange?: (infoImg: any) => void;
  defaultValue?: string;
  minHeight?: any;
  placeholder?: any;
  isRadio?: boolean;
}
const EditorEncyclopedie: FC<IEditorEncyclopedie> = ({
  handleChange,
  defaultValue = '',
  minHeight = 334,
  placeholder = '',
  isRadio = false,
}) => {
  const classes = style();
  const [value, setValue] = useState<string>(defaultValue);
  const textareaRef = useRef(null);
  const [firstTem, setFirstTem] = useState<boolean>(false);
  const handleChangeCitation = (e: any) => {
    setValue(e.target.value);
    if (handleChange) {
      handleChange(e.target.value);
      setFirstTem(true);
    }
  };

  const pastText = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    const newValue = `${value ?? ''}${text} `;
    setValue(newValue);
    if (handleChange) {
      handleChange(newValue);
    }
    setTimeout(() => {
      const event = new Event('input', { bubbles: true });
      (textareaRef.current as any)?.dispatchEvent(event);
    }, 0);
  };

  useEffect(() => {
    const textRef: any = textareaRef.current;
    if (textRef) {
      textRef.style.height = 'auto';
      textRef.style.height = `${textRef.scrollHeight - 15}px`;
    }
    if (!firstTem) {
      setTimeout(() => {
        const textRef1: any = textareaRef.current;
        if (textRef1) {
          textRef1.style.height = 'auto';
          textRef1.style.height = `${textRef1.scrollHeight - 15}px`;
        }
      }, 300);
    }
  }, [value, firstTem]);

  return (
    <div style={{ height: '100%', width: isRadio ? '100%' : '' }}>
      <textarea
        ref={textareaRef}
        style={{ minHeight }}
        className={classes.editArea}
        placeholder={placeholder}
        value={value}
        onPaste={pastText}
        onInput={handleChangeCitation}
      />
    </div>
  );
};

export default EditorEncyclopedie;
