/* eslint-disable react/no-unescaped-entities */
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { ebookBookTemplateActions } from '@orientaction/api-actions';
import ContainerAdmin from '@orientaction/commons/ContainerAdmin';
import MarginContainer from '@orientaction/commons/MarginContainer';
import { Layout } from '@orientaction/components';
import TestMainImage from '@orientaction/components/Admin/Test/Create/TestMainInfo/LeftImage/TestMainImage';
import { ebookBookValues } from '@orientaction/reducers';
import { getFromLS } from '@orientaction/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import style from '../style';
import './ebookMain.css';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span
        style={{
          fontSize: 26,
          margin: 8,
          border: '1px solid #E4E4F2',
          borderRadius: '50%',
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        +
      </span>
      <span style={{ fontSize: 16 }}>Importer une image</span>
      <span style={{ color: 'black', marginTop: 10 }}>Résolution idéale : 260 x 364 px</span>
    </div>
  );
};

const CreateEbook = (props: any) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const token = getFromLS('userToken') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const valuesEbooksTemplate = useSelector(ebookBookValues);

  const handleFileUrlChange = (urlC: string) => {
    setUrl(urlC);
  };

  const handleFileChange = async (fileC: any) => {
    setFile(fileC);
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('files', fileC);
      let idFILESTemps = localStorage.getItem('idFILESTemps') as any;

      if (!idFILESTemps) {
        idFILESTemps = [];
      } else {
        idFILESTemps = JSON.parse(idFILESTemps);
      }
      const response = await apiServiceNative.post(`/api/upload`, formData, true);
      idFILESTemps.push(response[0]?.id);
      localStorage.setItem('idFILESTemps', JSON.stringify(idFILESTemps));
      dispatch(
        ebookBookTemplateActions.updateField('img', {
          id: response[0]?.id,
          url: response[0]?.url,
        }),
      );
    } catch (error) {
      setLoading(false);
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    setUrl('');
    dispatch(ebookBookTemplateActions.updateField('img', null));
  };

  const handleFieldChange = (field: string, value: any) => {
    dispatch(ebookBookTemplateActions.updateField(field, value));
  };

  const postEbook = async () => {
    sessionStorage.setItem('fromEdit', 'true');
    history.push(`/admin/ebook/edit`);
  };

  return (
    <Layout isaddBackground={true} showOverFlow={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item={true} sm={12}>
              <Typography variant="h2" style={{ textTransform: 'none', textAlign: 'center' }}>
                Édition d’un ebook
              </Typography>
            </Grid>
          </Grid>
          <span className={classes.separator} />
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item={true} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <div className={classes.card}>
                  <h3 className={classes.label}>Titre du book</h3>
                  <TextField
                    id="create-test-title"
                    variant="outlined"
                    className={classes.formInput}
                    name="test-name"
                    value={url || valuesEbooksTemplate?.title}
                    onChange={(e) => {
                      handleFieldChange('title', e.target.value);
                    }}
                    placeholder="Indiquer ici le titre du jeu"
                  />

                  <h3 className={classes.label} style={{ marginTop: '25px' }}>
                    Image de couverture
                  </h3>
                  <div style={{ position: 'relative' }}>
                    {!!valuesEbooksTemplate?.img?.url && (
                      <span className={classes.closeBtn} onClick={handleDeleteFile}>
                        x
                      </span>
                    )}
                    <TestMainImage
                      fileUrl={valuesEbooksTemplate?.img?.url}
                      handleFileUrlChange={handleFileUrlChange}
                      handleFileChange={handleFileChange}
                      placeholder={placeholder}
                      pathPrefix="tests"
                      sectionTop={true}
                      useForGame={true}
                    />
                    {loading && (
                      <div className="loader-overlay">
                        <div className="loader" />
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !valuesEbooksTemplate?.img?.url || !valuesEbooksTemplate?.title || loading
                  }
                  className={classes.btnPrimary}
                  style={{ width: '100%', margin: '20px 0px' }}
                  onClick={postEbook}
                >
                  Éditer l'ebook
                </Button>
              </div>
            </Grid>
          </Grid>
        </MarginContainer>
      </ContainerAdmin>
    </Layout>
  );
};

export default CreateEbook;
